.farmer_inquiry {
  font-size: 13px;
  color: #222222;

  &.tabechoku_admin_inquiry {
    background-color: #fff;
    width: 680px;
    padding: 15px 30px;
    margin: 0 auto;

    .image-gallery .delete {
      text-indent: -999px;
    }

    * {
      font-size: 15px;
    }

    .is-title {
      font-weight: bold;
      background: #dbdbdb;
      border-left: 3px solid;
      font-size: 16px;
      padding: 0.5rem;
    }

    .control-button span {
      position: relative;
      display: inline-block;
      padding-left: 20px;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        border: 0px;
        border-top: solid 3px #363636;
        border-right: solid 3px #363636;
        transform: rotate(-135deg);
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -4px;
      }
    }

    img {
      max-width: 100%;
    }

    figure {
      margin: 0;
    }
  }

  .image-gallery {
    width: 100%;
    padding: 0;
    font-size: 0;
    display: flex;
    flex-flow: row wrap;

    .image {
      flex: auto;
      width: 180px;
      padding: 0 !important;
      border: 1px solid #fff;
      position: relative;

      &:last-child {
        position: relative;
        display: inline-block;
        overflow: hidden;
        vertical-align: middle;
      }
    }
  }

  .farmer-inquiry-category {
    font-size: 14px;
  }

  .farmer_inquiry_attention_txt {
    color: #c30d23;
    font-weight: bold;
    margin-top: 15px;
    text-align: center;

    img {
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 2px;
    }

    a {
      text-decoration: underline;
    }

    &.no-action-required {
      color: rgba(0, 0, 0, 0.5);

      img {
        display: none;
      }
    }
  }

  .farmer_inquiry_closed_txt {
    background-color: #f3f3f3;
    padding: 13px 10px;
    text-align: center;

    .farmer_inquiry_closed_contact {
      display: inline-block;
      margin-top: 4px;
      border: 2px solid #a8a8a8;
      background-color: #fff;
      padding: 6px 30px;
    }
  }

  .farmer_inquiry_attention_txt_in_index {
    color: #c30d23;
    font-weight: bold;
    text-align: center;
    display: inline;

    img {
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }

  .farmer_inquiry_info_list {
    margin-top: 25px;
    margin-bottom: 40px;
  }

  .farmer_inquiry_buy_date {
    margin-bottom: 5px;
  }

  .farmer_inquiry_product_info {
    overflow: hidden;
    margin-bottom: 15px;

    .farmer_inquiry_product_thumb {
      float: left;
      display: block;
      width: 70px;
      height: 70px;
      background-image: url("../../app/assets/images/product_sample.jpg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .selectFruitsThumb {
      background-image: url("../../app/assets/images/select_fruits_sample.jpg");
    }

    .selectRiceThumb {
      background-image: url("../../app/assets/images/select_rice_sample.jpg");
    }

    .farmer_inquiry_detail {
      margin-left: 82px;
      text-align: left;
    }

    .farmer_inquiry_name {
      margin-top: 0;
      font-size: 14px;
      font-weight: bold;
    }

    .farmer_inquiry_farm_name_link {
      font-size: 12px;
      color: #212121;
    }
  }

  .farmer_inquiry_thread_section_ttl {
    font-size: 17px;
    font-weight: bold;
    padding: 10px;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    text-align: left;
  }

  .farmer_inquiry_block {
    padding: 20px 15px 30px;
    text-align: left;

    &.attention_bgc {
      background: #fff5f5;
    }
  }

  .farmer_inquiry_thread_section_head {
    margin-bottom: 10px;

    .farmer_inquiry_thread_section_head_inner {
      display: flex;
      align-items: center;
    }

    .circle_wrap {
      width: 80%;

      a {
        color: inherit;
      }
    }

    .circle {
      display: flex;
      align-items: center;

      img {
        border-radius: 50%;
        width: auto;
        height: 36px;
      }
    }

    figcaption {
      font-weight: bold;
      margin-left: 10px;
    }
  }

  .farmer_inquiry_thread_del {
    width: 25%;
    min-width: 90px;
    text-align: right;

    .farmer_inquiry_thread_del_btn {
      background: none;
      border: 1px solid #a4a4a4;
      border-radius: 3px;
      padding: 5px 15px;
      color: #7d7d7d;
      cursor: pointer;
    }
  }

  .farmer_inquiry_thread_date {
    font-weight: bold;
    letter-spacing: 0.1px;
    font-size: 12px;
  }

  .farmer_inquiry_thread_main {
    margin-top: 7px;
  }

  .farmer_inquiry_block_container {
    .farmer_inquiry_block {
      border-top: 1px solid #dddddd;

      &:first-child {
        border: none;
      }

      &.delete_post {
        background-color: #f1f1f1;

        .farmer_inquiry_thread_date {
          color: #a5a5a5;
        }

        .farmer_inquiry_thread_main {
          font-weight: bold;
          color: #a5a5a5;
          text-align: center;
        }
      }
    }
  }

  .farmer_inquiry_reply_form {
    border: 1px solid #dddddd;
    text-align: left;
  }

  .farmer_inquiry_reply_form_head {
    background-color: $color-gray5;
  }

  .farmer_inquiry_reply_form_head_ttl {
    padding: 6px 13px;
    font-weight: bold;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
  }

  .farmer_inquiry_reply_form_body {
    padding: 0 10px 10px;

    .farmer_inquiry_reply_content {
      width: 100%;
      height: 100px;
      padding: 6px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  .farmer_inquiry_reply_uploader_block {
    margin-top: 10px;

    .farmer_inquiry_reply_uploader {
      position: static;
      margin-bottom: 30px;
      width: 100%;
      display: flex;
    }

    .image-upload-area {
      padding-top: 70px;
      cursor: pointer;
      background-color: #646464;
      background-image: url("../../app/assets/images/icon-camera.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 90%;
    }
  }

  .farmer_inquiry_reply_uploader {
    .upload-drop-zone {
      position: relative;
      width: 70px;
      flex-shrink: 0;
    }

    input[type="file"] {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
      cursor: pointer;
    }
  }

  .farmer_inquiry_reply_uploader_txt {
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #757575;
  }

  @media screen and (min-width: 768px) {
    &.account .container {
      padding: 30px;
    }

    .farmer_inquiry_block {
      padding: 20px 25px 30px;
    }

    .farmer_inquiry_thread_main {
      margin-top: 13px;
    }

    .farmer_inquiry_closed_txt {
      padding: 20px 10px;
    }
  }
}
