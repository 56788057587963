@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/mixin/arrow";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.p-productProducerPanel {
  --_repeat: 1fr;
  --_padding: 16px;
  --_award-border-top: 1px solid #{color.$color-gray4};
  --_award-border-left: none;
  --_award-padding-top: 16px;
  --_award-padding-left: 0;
  --_info-padding-bottom: 16px;
  --_header-inner-gap: 0px;

  display: grid;

  border-radius: 4px;
  background-color: color.$color-gray6;
  padding: var(--_padding);

  @include screen.screen("tablet-and-pc") {
    --_repeat: 1fr auto;
    --_padding: 32px;
    --_award-border-top: none;
    --_award-border-left: 1px solid #{color.$color-gray4};
    --_award-padding-top: 0;
    --_award-padding-left: 16px;
    --_info-padding-bottom: 0;
    --_header-inner-gap: 4px;
  }

  .p-productProducerPanel__badge {
    width: 16px;
    height: 16px;
    fill: color.$color-primary1;

    &.p-productProducerPanel__badge--normal {
      fill: color.$color-gray2;
    }
  }

  .p-productProducerPanel__infoBadge {
    display: flex;
    align-items: center;

    margin-top: 0; // FIXME : app/styles/user/Object/Utility/text.scss の p + p のマージンリセットします

    color: color.$color-primary1;
    @include typography.caption-bold;
  }

  .p-productProducerPanel__header {
    display: grid;
    gap: 8px;

    & + .p-productProducerPanel__details {
      border-top: 1px solid color.$color-gray4;
      margin-top: 16px;
    }
  }

  .p-productProducerPanel__headerInner {
    display: grid;
    gap: var(--_header-inner-gap);
    grid-template-columns: var(--_repeat);
  }

  .p-productProducerPanel__info {
    display: flex;
    align-items: center;
    gap: 16px;

    padding-bottom: var(--_info-padding-bottom);

    margin-top: 0; // FIXME : app/styles/user/Object/Utility/text.scss の p + p のマージンリセットします
  }

  .p-productProducerPanel__infoText {
    margin-top: 0; // FIXME : app/styles/user/Object/Utility/text.scss の p + p のマージンリセットします
  }

  .p-productProducerPanel__infoArea {
    margin-top: 0; // FIXME : app/styles/user/Object/Utility/text.scss の p + p のマージンリセットします

    @include typography.caption;
  }

  .p-productProducerPanel__infoName {
    margin-top: 0; // FIXME : app/styles/user/Object/Utility/text.scss の p + p のマージンリセットします

    @include typography.title-2;
  }

  .p-productProducerPanel__infoFounderOn {
    margin-top: 8px; // FIXME : app/styles/user/Object/Utility/text.scss の p + p のマージンリセットします

    color: color.$color-gray2;
    @include typography.caption;
  }

  .p-productProducerPanel__award {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    gap: 16px;

    border-top: var(--_award-border-top);
    border-left: var(--_award-border-left);

    padding-left: var(--_award-padding-left);
    padding-top: var(--_award-padding-top);
  }

  .p-productProducerPanel__awardHistory {
    display: grid;
    gap: 4px;
    grid-template-areas:
      "historyTitle historyTitle"
      "historyCount hallOfFame";
    grid-template-columns: auto 1fr;
    align-content: center;
    align-items: center;
  }

  .p-productProducerPanel__awardHistoryTitle {
    grid-area: historyTitle;
    @include typography.subTitle-small;
  }

  .p-productProducerPanel__awardHistoryCount {
    grid-area: historyCount;
    @include typography.caption;
  }

  .p-productProducerPanel__awardHallOfFame {
    grid-area: hallOfFame;
  }

  .p-productProducerPanel__awardIcon {
    display: grid;
    justify-items: center;
    gap: 4px;

    @include typography.caption;
  }

  .p-productProducerPanel__details {
    border-top: 1px solid color.$color-gray4;
    padding-top: 16px;

    & + .p-productProducerPanel__details {
      border-top: 1px solid color.$color-gray4;
      margin-top: 16px;
    }
  }

  .p-productProducerPanel__detailsLink {
    position: relative;
    display: flex;

    margin-bottom: 16px;

    color: color.$color-primary1;
    @include typography.subTitle;

    &::before {
      @include arrow.arrow(color.$color-primary2, 2px, 45deg);
      position: absolute;
      right: 0;
      top: 4px;
    }
  }

  .p-productProducerPanel__detailsCommitment {
    display: flex;
    gap: 8px;
    align-items: flex-start;

    @include typography.subTitle-small;

    & + .p-productProducerPanel__detailsCommitment {
      margin-top: 8px;
    }
  }

  .p-productProducerPanel__detailsNo {
    background: color.$color-gray0;
    border-radius: 10px;

    padding: 1px 6px;

    color: color.$color-primary1;
    @include typography.subTitle-small;
  }
}
