@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.p-magazinesToc {
  .p-magazinesToc__title {
    text-align: center;

    padding: 12px 0;
    margin: 0;

    @include typography.subTitle;
  }

  .p-magazinesToc__list {
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(1, 1fr);
    background-color: color.$color-gray5;
    border: 1px solid color.$color-gray5;

    @include screen.screen("tablet-and-pc") {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .p-magazinesToc__item {
    background-color: color.$color-gray0;

    &.p-magazinesToc__item--displayNoneOnlySp {
      display: none;
      @include screen.screen("tablet-and-pc") {
        display: block;
      }
    }
  }

  .p-magazinesToc__link {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 20px;
    align-items: center;

    font-size: font.$font-size4;
    line-height: 1.5;
    letter-spacing: 0.25px;

    padding: 12px;

    &::after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2211%22%20height%3D%227%22%20viewBox%3D%220%200%2011%207%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10%201.5L6.11091%205.38909C5.91565%205.58435%205.59907%205.58435%205.40381%205.38909L1.51472%201.5%22%20stroke%3D%22%239F8745%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
