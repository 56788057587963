@use "../../../../Foundation/variables/color";
@use "../../../../Foundation/mixin/screen";
@use "../../../../Foundation/mixin/typography";

// TODO： .mypage-orders-listing を .p-ordersListing に変更 して別ファイルにしただけです。色々なクラスが混ざっているので、コンポーネントを整理する必要があります

.p-ordersListing {
  .shipping-order {
    max-width: 700px;
    background-color: color.$color-gray0;
    padding: 28px 16px;
    margin: 0 auto 20px;
    @include screen.screen("tablet-and-pc") {
      padding: 28px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .order-item {
    position: relative;
    display: flex;
    gap: 16px;
    padding: 20px 0;

    + .order-item {
      border-top: 1px solid color.$color-gray4;
    }

    .order-item__thumbnail {
      display: block;
      height: 48px;
      width: 48px;
      min-width: 48px;
      @include screen.screen("tablet-and-pc") {
        height: 70px;
        width: 70px;
        min-width: 70px;
      }

      /* stylelint-disable tabechoku/selector-type-allowed-list */
      figure {
        height: 100%;
        background: {
          image: url(" ../../app/assets/images/product_sample.jpg");
          repeat: no-repeat;
          position: center center;
          size: cover;
        }
      }

      figure.selectFruitsThumb {
        height: 100%;
        background: {
          image: url(" ../../app/assets/images/select_fruits_sample.jpg");
          repeat: no-repeat;
          position: center center;
          size: cover;
        }
      }

      figure.selectGochisoThumb {
        height: 100%;
        background: {
          image: url(" ../../app/assets/images/select_gochiso_sample.jpg");
          repeat: no-repeat;
          position: center center;
          size: cover;
        }
      }

      figure.selectMealsThumb {
        height: 100%;
        background: {
          image: url(" ../../app/assets/images/select_meals_sample.jpg");
          repeat: no-repeat;
          position: center center;
          size: cover;
        }
      }

      figure.selectRiceThumb {
        height: 100%;
        background: {
          image: url(" ../../app/assets/images/select_rice_sample.jpg");
          repeat: no-repeat;
          position: center center;
          size: cover;
        }
      }

      /* stylelint-enable tabechoku/selector-type-allowed-list */
    }

    .order-item__detail {
      width: calc(100% - 48px - 16px);
      text-align: left;

      .detail-item-small {
        @include typography.caption;
        color: color.$color-gray2;
      }

      .detail-item-normal {
        @include typography.caption;
      }

      .detail-item-strong {
        @include typography.subTitle;
        margin: 0 0 8px;
      }

      .fixed-term-subscription-name {
        @include typography.label;
      }
    }
  }

  .shipping-order-item {
    display: grid;
    grid-template-columns: 48px 1fr 60px;
    gap: 0 16px;
    padding: 16px 0;
    @include screen.screen("tablet-and-pc") {
      grid-template-columns: 70px 1fr 60px;
    }

    + .order-item {
      border-top: 1px solid color.$color-gray4;
    }

    .gift-card-order-item__thumbnail--square {
      display: block;
      height: 48px;
      width: 48px;
      min-width: 48px;
      @include screen.screen("tablet-and-pc") {
        height: 70px;
        width: 70px;
        min-width: 70px;
      }

      figure {
        height: 100%;
        background: {
          image: url(" ../../app/assets/images/gift_card_product_sample.jpg");
          repeat: no-repeat;
          position: center center;
          size: cover;
        }
      }
    }

    .shipping-order-item__thumbnail {
      display: block;
      height: 48px;
      width: 48px;
      min-width: 48px;
      border-radius: 50%;
      @include screen.screen("tablet-and-pc") {
        height: 70px;
        width: 70px;
        min-width: 70px;
      }

      figure {
        height: 100%;
        border-radius: 50%;
        background: {
          image: url(" ../../app/assets/images/product_sample.jpg");
          repeat: no-repeat;
          position: center center;
          size: cover;
        }
      }
    }

    .shipping-order-item__detail {
      .detail-item-small {
        @include typography.caption;
        color: color.$color-gray2;
      }

      .detail-item-strong {
        @include typography.subTitle;
        margin: 4px 0 8px;
      }
    }

    .shipping-order-item__order-detail-btn {
      background-color: color.$color-gray4;
      font-size: 10px;
      width: 60px;
      height: 60px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .producer {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;

    img {
      width: 24px;
      height: 24px;
      border-radius: 100%;
    }

    .name {
      @include typography.caption;
    }
  }
}
